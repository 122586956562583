.App {
  text-align: center;
  height: 100vh;
}

.App h1 {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}